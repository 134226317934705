$quantity: 240

html, body
  height: 100%

body
  background-color: #ffffff
  background-size: cover
  display: grid
  justify-content: center
  align-content: center

.title
  font-size: 10vw
  .char
    color: #00a5ff
    animation: text-flash 4s infinite ease-in-out

.dev-mode
  position: absolute
  right: 0
  bottom: 0
  margin: 48px

.firefly
  position: fixed
  left: 50%
  top: 50%
  width: 2px
  height: 2px
  margin: -0.2vw 0 0 9.8vw
  animation: ease 2000s alternate infinite
  pointer-events: none

  &::before,
  &::after
    content: ''
    position: absolute
    width: 100%
    height: 100%
    border-radius: 50%
    transform-origin: -10vw

  &::before
    background: #ffffff
    opacity: 0.4
    animation: drift ease alternate infinite

  &::after
    background: #000000
    opacity: 0
    box-shadow: 0 0 0 0 #050000
    animation: drift ease alternate infinite, flash ease infinite

@media screen and (max-width: 480px)
  .dev-mode
    display: none

@media screen and (min-width: 1200px)
  .title
    font-size: 6vw

// Randomize Fireflies Motion
@for $i from 1 through $quantity

  $steps: random(120) + 10
  $rotationSpeed: random(120) + 8s

  .firefly:nth-child(#{$i})
    animation-name: move#{$i}

    &::before
      animation-duration: #{$rotationSpeed}

    &::after
      animation-duration: #{$rotationSpeed}, random(6000) + 5000ms
      animation-delay: 0ms, random(8000) + 500ms

  @keyframes move#{$i}
    @for $step from 0 through $steps
      #{$step * (100 / $steps)}%
        transform: translateX(random(100) - 50vw) translateY(random(100) - 50vh) scale(random(75) / 100 + .25)

@keyframes drift
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@keyframes flash
  0%, 30%, 100%
    opacity: 0
    box-shadow: 0 0 0 0 #003756
  5%
    opacity: 1
    box-shadow: 0 0 4px 4px #b8e7fb

@keyframes text-flash
  0%
    color: #00a5ff
  50%
    color: #003756
  100%
    color: #00a5ff