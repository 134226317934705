.landing {
  display: grid;
  justify-content: center;
  align-content: center;
  height: 100vh;
  text-align: center;
}

.landing__social-media-links {
  display: flex;
  justify-content: center;
}

.landing__enter {
  margin-top: 64px;
  font-family: Montserrat, serif;
  /*font-size: 12vw;*/
  font-weight: bold;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 16px;
}
