.social-media-link {
  display: grid;
  /*align-items: center;*/
  /*justify-items: center;*/
  border-radius: 50%;
  /*margin: 0 16px;*/
  padding: 12px;
}

.social-media-link:hover {
  background-color: rgb(0, 165, 255, .1);
  cursor: pointer;
}
