.logo {
  pointer-events: none;
  line-height: normal;
  font-size: 12vw;
}

.logo-bracket {
  font-weight: 200;
  font-family: Montserrat, serif;
  color: #000000;
}

.logo-text {
  margin: 0 32px;
  color: #00a5ff;
}

@media screen and (min-width: 1200px) {
  .logo {
    font-size: 6vw
  }
}
